/**
 * @file Layout
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import Footer from './Footer'
import '../scss/app.scss'

const Layout = ({ children, data }) => (
  <>
    <StaticQuery
      query={graphql`
        query titleQuery {
          dataYaml {
            title
            meta {
              description
              keywords
            }
          }
        }
      `}
      render={({ dataYaml }) => (
        <>
          <Helmet
            bodyAttributes={{
              class: 'body'
            }}
            title={dataYaml.title}
            meta={[
              {
                name: 'description',
                content: dataYaml.meta.description
              },
              {
                name: 'keywords',
                content: dataYaml.meta.keywords 
              },
            ]}
          />
          <Header />
          <main className="main">
            {children}
          </main>
          {/* <Footer /> */}
        </>
      )}
    />
  </>
)

export default Layout
