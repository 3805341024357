/**
 * @file Header
 */

import React from 'react'
import { Link } from 'gatsby'
import Navigation from '../Navigation'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class Header extends React.Component {
  state = {
    atTop: false,
    navigationVisible: false,
    navigationAlt: false
  }

  toggleNavigation = (event) => {
    this.setState({
      navigationVisible: !this.state.navigationVisible
    })
  }

  componentDidMount() {
    const showAnim = gsap.from(this.headerRef, {
      yPercent: -340,
      paused: true,
      duration: 0.4
    }).progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (!this.state.navigationVisible) {
          self.direction === -1 ? showAnim.play() : showAnim.reverse()
        }

        if (self.progress > 0.00051) {
          this.setState({
            atTop: true
          })
        } else {
          this.setState({
            atTop: false
          })
        }
      }
    })
  }

  render() {
    return (
      <>
        <header className={`header ${this.state.atTop && 'header--at-top'}`} ref={ref => this.headerRef = ref}>
          <div className="header__content">
            <Link to="/">
              <div className={`header__logo ${this.state.navigationVisible ? `header__logo--active` : ``}`}>
                <span className="header__logo-letter">
                  M
                </span>
              </div>
            </Link>
            {/* <button
              type="button"
              className={`header__navigation-toggle
                ${this.state.navigationVisible ? `header__navigation-toggle--active` : ``}
                ${this.state.navigationAlt ? `header__navigation-toggle--alt` : ``}
              `}
              onClick={this.toggleNavigation}
              >
              <span className="header__navigation-toggle-line" />
              <span className="header__navigation-toggle-line" />
            </button> */}
          </div>
        </header>
        <Navigation visible={this.state.navigationVisible} toggleNavigation={this.toggleNavigation} />
      </>
    )
  }
}

export default Header
