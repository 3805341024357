/**
 * @file Navigation
 */

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

class Navigation extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.visible !== this.props.visible) {
      return true
    }

    return false
  }

  handleClick = (e) => {
    e.preventDefault()
    this.scrollToElement(e)
    this.props.toggleNavigation();
  }

  scrollToElement = (e) => {
    scrollTo(e.target.getAttribute('href'))
  }

  render() {
    return (
      <div>
        <div className={`navigation ${this.props.visible ? `navigation--visible` : `navigation--hidden`}`}>
          <div className="navigation__content">
            <StaticQuery
              query={graphql`
                query DataQuery {
                  dataYaml {
                    links {
                      label
                      target
                    }
                    social {
                      link
                      network
                    }
                  }
                }
              `}
              render={({ dataYaml }) => {
                return (
                  <>
                    <nav>
                      <div className="navigation__items">
                        {dataYaml.links.map((link, index) => (
                          <div key={`navigation-item-${index}`} className="navigation__item">
                            <a href={`#${link.target}`} className="navigation__link" onClick={this.handleClick}>
                              {link.label}
                            </a>
                          </div>
                        ))}
                      </div>
                    </nav>
                    <div className={`social social--black`}>
                      {dataYaml.social.map((item, index) => (
                        <a key={`social-black-item-${index}`} className="social__link" href={item.link} target="_blank" rel="noopener noreferrer">
                          <i className={`social__icon i-${item.network.toLowerCase()}`}></i>
                        </a>
                      ))}
                    </div>
                  </>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Navigation
